<template>
  <div class="NewsDetail">
    <div class="banner">
      <div class="container">
      </div>
    </div>
    <div class="cont pd40">
      <div class="container">
        <div class="head">{{newsDetail.title}}</div>
        <div class="date"><span>Source from: {{newsDetail.student}}</span> <span>{{newsDetail.updated_at}}</span> <span>Read:{{newsDetail.hits}}</span> </div>
        <el-divider></el-divider>
        <div class="__cont__"
             v-html="newsDetail.content"></div>
        <el-divider>end</el-divider>
        <div class="_navss">
          <a :href="'./'+newsDetail.prev.id ? newsDetail.prev.id:''"> <span>Previous Article:</span> {{newsDetail.prev.title ? newsDetail.prev.title:'No more'}}</a>
          <a :href="'./'+newsDetail.next.id ? newsDetail.next.id:''"> <span>Next Article:</span> {{newsDetail.next.title ? newsDetail.next.title:'No more'}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosApi from "../api/api.js";
export default {
  data () {
    return {
      newsDetail: {
        newsDetail: '',
        content: '',
        updated_at: '',
        next: '',
        prev: '',
      }
    }
  },
  beforeMount () {
    console.log()
    axiosApi(
      'newsinfo/' + this.$route.params.id, {}, 'get'
    ).then((res) => {
      this.newsDetail = res.data
    })
  },
  methods () {

  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/banner.png) center center no-repeat;
  height: 300px;
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.cont {
  .head {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
  .date {
    font-size: 14px;
    color: #545454;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 20px;
    span {
      margin: 0 20px;
    }
  }
  ._navss {
    display: flex;
    justify-content: center;
    a {
      width: 40%;
      display: block;
      line-height: 1;
      transition: ease 0.5s;
      margin-bottom: 10px;
      padding: 10px;
      span {
        display: block;
        margin-bottom: 10px;
        color: #ffc107;
        font-weight: bold;
      }
      &:nth-child(1) {
        text-align: right;
        border-right: 1px solid #ffc107;
      }
      &:hover {
        color: #ffc107;
      }
    }
  }
}
</style>

<style lang="scss">
.__cont__ {
  margin-top: 30px;
  img {
    max-width: 100%;
  }
}
</style>